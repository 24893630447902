<template>
  <div class="board">
    <canvas
      id='carte'
      ref='carte'
      :width="size.w"
      :height="size.h"
      max-width="100%"
      tabindex='0'
      style="border:1px solid #000000;"
    ></canvas>
  </div>
  <div id="texteRemplacement" v-if="petit">
    <p v-for="p in texte.split('\n')" v-bind:key="p">
      {{ p }}
    </p>
  </div>
</template>

<script>

import {
  defineComponent, onMounted, ref, reactive, nextTick, toRefs, watch,
} from 'vue';
import axios from 'axios';

export default defineComponent({
  name: 'Visionneuse',
  props: ['texteEnvoye', 'taille'],
  setup(props) {
    const myCanvas = ref(null);
    const carte = ref(null);
    const { texteEnvoye, taille } = toRefs(props);
    const texte = ref('');
    const imgFond = ref('');
    const imgEtoile = ref('');

    async function getImage(chemin) {
      const image = await axios.get(`${process.env.VUE_APP_PUBLICPATH}img/${chemin}`, { responseType: 'arraybuffer' })
        .then((res) => `data:${res.headers['content-type']};base64,${btoa(String.fromCharCode(...new Uint8Array(res.data)))}`)
        .catch((e) => {
          console.log(e);
        });
      return image;
    }

    let fpsInterval;
    let startTime;
    let now;
    let then;
    let elapsed;

    function getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    }

    function genererEtoile() {
      return ({
        taille: getRandomInt(10) + 10,
        position: {
          x: Math.random(),
          y: Math.random(),
        },
        deviation: {
          x: {
            valeur: getRandomInt(18) - 9,
            sens: 1,
            amplitude: 5 + getRandomInt(5),
          },
          y: {
            valeur: getRandomInt(8) - 4,
            sens: 1,
            amplitude: 5 + getRandomInt(5),
          },
        },
        grossissement: {
          valeur: getRandomInt(5) - 3,
          sens: 1,
        },
      });
    }

    const etoiles = ref(new Array(50).fill(null).map(() => (genererEtoile())));
    const date = ref([
      {
        valeur: {
          chiffre: '2',
          position: 0,
          sens: 1,
        },
      },
      {
        valeur: {
          chiffre: '0',
          position: 3,
          sens: -1,
        },
      },
      {
        valeur: {
          chiffre: '2',
          position: -3,
          sens: 1,
        },
      },
      {
        valeur: {
          chiffre: '1',
          position: -3,
          sens: -1,
        },
      },
    ]);
    const rapport = ref(0);
    const petit = ref((window.innerWidth < 750));

    const size = reactive({
      w: window.innerWidth * taille.value,
      h: window.innerHeight * taille.value,
    });

    const tailleFond = reactive({
      w: 0,
      h: 0,
    });

    function drawText() {
      const fontSize = 0.05 * size.w - 10;
      myCanvas.value.font = `${fontSize}px Caviar Dream`;
      myCanvas.value.textAlign = 'center';
      myCanvas.value.fillStyle = 'white';
      myCanvas.value.strokeStyle = 'black';
      myCanvas.value.lineWidth = 0.006 * size.w - 10;
      const x = (size.w * 9) / 13;
      const y = (size.h * 5) / 7;
      const lineHeight = fontSize;
      const lines = texte.value.split('\n');
      for (let i = 0; i < lines.length; i += 1) {
        myCanvas.value.fillText(
          lines[lines.length - i - 1],
          x,
          y + ((lines.length - 1) * lineHeight) / 2 - (i * lineHeight),
        );
      }
    }

    function initCarte() {
      requestAnimationFrame(initCarte);
      now = Date.now();
      elapsed = now - then;

      if (elapsed > fpsInterval) {
        then = now - (elapsed % fpsInterval);
        petit.value = window.innerWidth < 750;
        const etoile = new Image();
        etoile.src = imgEtoile.value;
        const background = new Image();
        background.src = imgFond.value;
        background.onload = () => {
          rapport.value = background.naturalWidth / background.naturalHeight;
          tailleFond.w = background.naturalWidth;
          tailleFond.h = background.naturalHeight;
          size.w = window.innerWidth * taille.value;
          size.h = size.w / rapport.value;
          if (size.w > window.innerWidth) {
            size.h = size.w / rapport.value;
          } else if (size.h > window.innerHeight) {
            size.h = window.innerHeight * taille.value;
            size.w = size.h * rapport.value;
          }
          nextTick(() => {
            try {
              myCanvas.value.drawImage(background, 0, 0, size.w, size.h);
              // on dessine les étoiles
              etoiles.value.forEach((e) => {
                if (e.grossissement.valeur > 2 && e.grossissement.sens === 1) {
                  e.grossissement.sens = -1;
                }
                if (e.grossissement.valeur < -3 && e.grossissement.sens === -1) {
                  e.grossissement.sens = 1;
                }
                if (e.deviation.x.valeur > e.deviation.x.amplitude && e.deviation.x.sens === 1) {
                  e.deviation.x.sens = -1;
                }
                if (e.deviation.x.valeur < -e.deviation.x.amplitude && e.deviation.x.sens === -1) {
                  e.deviation.x.sens = 1;
                }
                if (e.deviation.y.valeur > e.deviation.y.amplitude && e.deviation.y.sens === 1) {
                  e.deviation.y.sens = -1;
                }
                if (e.deviation.y.valeur < -e.deviation.y.amplitude && e.deviation.y.sens === -1) {
                  e.deviation.y.sens = 1;
                }
                e.deviation.x.valeur += e.deviation.x.sens;
                e.deviation.y.valeur += e.deviation.y.sens;
                e.grossissement.valeur += e.grossissement.sens;
                myCanvas.value.drawImage(etoile,
                  (e.position.x * size.w) + e.deviation.x.valeur - e.grossissement.valeur / 2,
                  (e.position.y * size.h) + e.deviation.y.valeur - e.grossissement.valeur / 2,
                  (e.taille + e.grossissement.valeur) * (size.w / background.naturalWidth),
                  (e.taille + e.grossissement.valeur) * (size.h / background.naturalHeight));
              });
              // on dessine 2021
              const fontSize = 0.1 * size.w - 10;
              myCanvas.value.font = `${fontSize}px Caviar Dream`;
              myCanvas.value.textAlign = 'center';
              myCanvas.value.fillStyle = 'white';
              myCanvas.value.strokeStyle = 'black';
              myCanvas.value.lineWidth = 0.006 * size.w - 10;
              const x = (size.w * 7) / 12;
              const y = (size.h * 4) / 9;
              const ecart = 0.07 * size.w - 10;
              date.value.forEach((ch, i) => {
                if (ch.valeur.position > 6 && ch.valeur.sens === 1) {
                  ch.valeur.sens = -1;
                }
                if (ch.valeur.position < -6 && ch.valeur.sens === -1) {
                  ch.valeur.sens = 1;
                }
                ch.valeur.position += 1 * ch.valeur.sens;
                myCanvas.value.fillText(
                  ch.valeur.chiffre,
                  x + (ecart * i),
                  y + ch.valeur.position,
                );
              });
            } catch (e) {
              console.log(`ERREUR DE CHARGEMENT D'IMAGE: ${e}`);
            }
            if (!petit.value) {
              drawText();
            }
          });
        };
      }
    }

    function startAnimating(fps) {
      fpsInterval = 1000 / fps;
      then = Date.now();
      startTime = then;
      console.log(startTime);
      initCarte();
    }

    window.addEventListener('resize', initCarte);

    watch(texteEnvoye, (x) => {
      texte.value = x;
    });

    onMounted(async () => {
      imgFond.value = await getImage('fond_2021.jpeg');
      imgEtoile.value = await getImage('etoile.png');
      const c = carte.value;
      const ctx = c.getContext('2d');
      myCanvas.value = ctx;
      startAnimating(20);
    });

    return {
      myCanvas,
      size,
      texte,
      petit,
      carte,
      tailleFond,
    };
  },
});

</script>

<style scoped>
@font-face {
  font-family: 'Caviar Dream';
  src: local('Caviar Dream'), url(~@/assets/fonts/CaviarDreams.ttf) format('truetype');
}

#texteRemplacement {
  font-size: 20pt;
  font-family: 'Caviar Dream';
}

#myCanvas {
  border: 1px solid grey;
}

</style>
